import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as fuelApi from "../../api/fuelApi";
import { dateFormatter } from "../../common/dateFormatter";
import { decimalFormatter } from "../../common/decimalFormatter";
import { escapeRegExp } from "../../common/escapeRegExp";
import { sortAlphabetically } from "../../common/sortAlphabetically";
import * as fuelActions from "../../redux/actions/fuelActions";
import store from "../../redux/configureStore";
import DeleteDialog from "../Delete/DeleteDialog";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import "./fuel.css";
import FuelNew from "./FuelNew";
var _ = require("lodash");

const FuelView = (props) => {
   //////////////////////////init //////////////////////////////////////////////////////////
   const history = useHistory();
   const handleError = useErrorHandler();

   const { searchQuery = "" } = useSelector((state) => state.fuel);

   const [tariffList, setTariffList] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [gridLoading, setGridLoading] = useState(true);
   const [deleteModal, setDeleteModal] = useState(false);
   const [newTariffModal, setNewTariffModal] = useState(false);
   const [selectedTariff, setSelectedTariff] = useState(null);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [showAlert, setShowAlert] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [readOnly, setReadOnly] = useState(false);

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: "1rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
      colCellTitle: {
         "& .MuiDataGrid-treeDataGroupingCell span": {
            display: "none",
         },
         "& .MuiDataGrid-treeDataGroupingCellToggle .MuiSvgIcon-root": {
            color: "#002D72",
            width: "2em",
            height: "2rem",
         },
      },
   }));

   const classes = useStyles();

   function newFuelClick() {
      try {
         setNewTariffModal(true);
         setOpenDrawer(false);
      } catch (err) {
         handleError(err);
      }
   }

   const headerData = [
      { id: "fuelTariffName", allign: "left", label: "Fuel Tariff Name", type: "string" },
      { id: "customerName", allign: "left", label: "Customer Name", type: "string" },
      { id: "masterAccount", allign: "left", label: "Master Account", type: "string" },
      { id: "fscSchedule", allign: "left", label: "FSC Schedule", type: "string" },
      { id: "effectiveDate", allign: "left", label: "Effective Date", type: "date" },
      { id: "fscBasedOn", allign: "left", label: "FSC Based On", type: "string" },
      { id: "fsc", allign: "left", label: "FSC", type: "string" },
      { id: "modifiedDate", allign: "left", label: "Modified Date", type: "date" },
   ];

   useEffect(() => {
      //Always get latest upon load.
      props.getTariffs();
   }, []);

   const createTableList = async (tariffs) => {
      const tariffList = [];

      await sortAlphabetically(tariffs).forEach(
         async ({ _id, name, add_date, edit_date, effectiveDate, fscBasedOn, fscSchedule, tariffInfo }, index) => {
            const customers = [];
            await props.customers.forEach((customer) => {
               // check if customer has fuelTariffs property
               if (customer.fuelTariffs && customer.fuelTariffs.length > 0) {
                  customer.fuelTariffs.forEach(({ fuelTariff_id }) => {
                     if (fuelTariff_id === _id) {
                        customers.push(customer);
                     }
                  });
               }
            });

            const n = customers.length > 1 ? customers.length + 1 : 1;

            for (let i = 0; i < n; i++) {
               let hierarchy = [`${name}`];
               let customerName = "";
               let masterAccount = "";
               let isAChild = false;
               let fuelTariffName = name;

               if (customers.length === 1) {
                  customerName = customers[0].name;
                  masterAccount = customers[0].code;
               }

               if (customers.length > 1 && customers.length < 50 && i === 0) {
                  customerName = "MULTIPLE";
                  masterAccount = "MULTIPLE";
               }

               if (customers.length > 1 && customers.length < 50 && i !== 0) {
                  fuelTariffName = "";
                  hierarchy = [`${name}`, customers[i - 1].name];
                  customerName = customers[i - 1].name;
                  masterAccount = customers[i - 1].code;
                  isAChild = true;
               }

               if (customers.length >= 50) {
                  customerName = "MULTIPLE";
                  masterAccount = "MULTIPLE";
               }

               const newTariff = {
                  _id,
                  id: `${_id}${i}`,
                  hierarchy,
                  isAChild,
                  fuelTariffName,
                  customerName,
                  masterAccount,
                  modifiedDate: edit_date ? edit_date : add_date,
                  fscSchedule: fscSchedule ? fscSchedule : "",
                  effectiveDate: effectiveDate ? dateFormatter(effectiveDate) : "",
                  fscBasedOn: fscBasedOn ? fscBasedOn : "",
                  fsc: tariffInfo[0].ppr
                     ? `${decimalFormatter(tariffInfo[0].ppr)}%`
                     : decimalFormatter(tariffInfo[0].cpm)
                     ? `$${decimalFormatter(tariffInfo[0].cpm)}/mi`
                     : "",
               };

               tariffList.push(newTariff);
            }
         },
      );

      setTariffList(tariffList);
      !searchQuery && setTableData(tariffList);
      setGridLoading(false);
   };

   const onChangeFilter = (event, searchValue = null) => {
      try {
         setGridLoading(true);

         //store in redux
         store.dispatch(fuelActions.setSearchQuery(searchValue ? searchValue : event.target.value));

         //create blank array for return
         var filteredObjects = [];
         //this turns values into lowercase strings
         var regValue = new RegExp(
            searchValue ? searchValue.toLowerCase() : escapeRegExp(event.target.value).toLowerCase(),
            "g",
         );

         //loop through header data since it contains the field names we need to check
         headerData.map((header) => {
            var filter = [];
            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = tariffList.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id] ? filt[header.id].toLowerCase().match(regValue) : null;
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = tariffList.filter(
                  (filt) => filt[header.id === (searchValue ? searchValue : event.target.value)],
               );
            }

            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });
         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         if (filteredObjects) {
            if (filteredObjects.length) {
               Object.keys(filteredObjects).forEach(function (key) {
                  filteredObjects[key].id = filteredObjects[key]._id;
               });
            }
         }

         //set the hook with the newly filtered data and it will render
         setTableData(filteredObjects);
         setGridLoading(false);
      } catch (err) {
         setGridLoading(false);
         handleError(err);
      }
   };

   useEffect(() => {
      createTableList(props.tariffs);
   }, [props.tariffs]);

   useEffect(() => {
      if (searchQuery) {
         onChangeFilter(null, searchQuery);
      }
   }, [tariffList]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "fuel");
      let userReadOnly = false;

      if (!props.user.security.admin && curUserRoute[0].permission === "readOnly") {
         userReadOnly = true;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   function handleEditClick(tariff) {
      try {
         var newSelectedTariff = _.cloneDeep(tariff);
         history.push({ pathname: `/fuel/details/${newSelectedTariff.row._id}` });
      } catch (err) {
         handleError(err);
      }
   }

   function handleDeleteClick(tariff) {
      try {
         setSelectedTariff(tariff.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   const deleteTariff = () => {
      setDeleteModal(!deleteModal);

      fuelApi
         .deleteTariff(selectedTariff._id)
         .then((resp) => {
            //success
            openAlertMessage("Tariff Successfully Deleted.", "success");

            //update original record in termlist
            props.getTariffs();
         })
         .catch((err) => openAlertMessage(`Error deleting terminal: ${err}`, "error"));
   };

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         <div id={"fuelView"} className={classes.layoutMain}>
            <div id={"fuelViewHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={7}>
                     <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                        <Link color="inherit" style={{ cursor: "pointer" }} onClick={() => breadcrumbOnClick("/fuel")}>
                           <h3 style={{ fontWeight: "500" }}>{"Fuel"}</h3>
                        </Link>
                     </Breadcrumbs>
                  </Grid>
                  <Grid item xs={3} display="flex" justifyContent="flex-end">
                     <TextField
                        style={{
                           width: "100%",
                           borderRadius: "3px",
                        }}
                        id="filled-basic"
                        placeholder="Search"
                        size="small"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <SearchIcon style={{ color: "#000000DE" }} />
                              </InputAdornment>
                           ),
                        }}
                        value={searchQuery}
                        onChange={onChangeFilter}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <Tooltip title="New Fuel" arrow>
                        <IconButton
                           style={{ float: "right", marginRight: "1rem" }}
                           variant="contained"
                           color="primary"
                           onClick={newFuelClick}
                           disabled={readOnly}
                        >
                           <AddIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           sx={{ marginBottom: "0.25rem" }}
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"fuelViewBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                     <Paper elevation={3}>
                        <StyledDataGrid
                           gridHeight={"785px"}
                           loading={gridLoading}
                           treeData
                           getTreeDataPath={(row) => row.hierarchy}
                           groupingColDef={{
                              headerName: "",
                              width: 65,
                              resizable: false,
                           }}
                           className={classes.colCellTitle}
                           columns={[
                              {
                                 field: "fuelTariffName",
                                 headerName: "Fuel Tariff Name",
                                 description: "Name description of the fuel tariff.",
                                 type: "string",
                                 flex: 0.16,
                              },
                              {
                                 field: "customerName",
                                 headerName: "Customer Name",
                                 description: "Name of the Customer",
                                 type: "string",
                                 flex: 0.16,
                              },
                              {
                                 field: "masterAccount",
                                 headerName: "Master Account",
                                 description: "Code assigned to the customer",
                                 type: "string",
                                 flex: 0.1,
                              },
                              {
                                 field: "fscSchedule",
                                 headerName: "FSC Schedule",
                                 description: "FSC Schedule",
                                 type: "string",
                                 flex: 0.16,
                              },
                              {
                                 field: "effectiveDate",
                                 headerName: "Effective Date",
                                 description: "Effective Date",
                                 type: "date",
                                 flex: 0.1,
                              },
                              {
                                 field: "fscBasedOn",
                                 headerName: "FSC Based On",
                                 description: "FSC Based On",
                                 type: "string",
                                 flex: 0.1,
                              },
                              {
                                 field: "fsc",
                                 headerName: "FSC",
                                 description: "PPR or DPM",
                                 type: "string",
                                 flex: 0.06,
                              },
                              {
                                 field: "modifiedDate",
                                 headerName: "Date Modified",
                                 description: "Date Modified",
                                 type: "date",
                                 valueFormatter: ({ value }) => dateFormatter(value),
                                 flex: 0.1,
                              },
                              {
                                 field: "isAChild",
                                 headerName: " ", //This is a space because material-UI default to field unless there is a value.
                                 type: "string",
                                 sortable: false,
                                 filterable: false,
                                 renderCell: (params) => (
                                    <Box sx={{ display: params.value && "none" }}>
                                       <Tooltip title="Edit Fuel" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="primary"
                                             size="small"
                                             onClick={() => handleEditClick(params)}
                                          >
                                             <EditIcon />
                                          </IconButton>
                                       </Tooltip>
                                       |
                                       <Tooltip title="Delete Fuel" arrow>
                                          <IconButton
                                             variant="contained"
                                             color="secondary"
                                             size="small"
                                             onClick={() => handleDeleteClick(params)}
                                             disabled={readOnly}
                                          >
                                             <DeleteForeverIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </Box>
                                 ),
                                 flex: 0.06,
                              },
                           ]}
                           rows={tableData ? tableData : null}
                        ></StyledDataGrid>
                     </Paper>
                  </Grid>
               </Grid>
               {/* modal */}
               <FuelNew
                  open={newTariffModal}
                  handleClose={() => setNewTariffModal(!newTariffModal)}
                  getTariffs={props.getTariffs}
                  openAlertMessage={openAlertMessage}
                  user={props.user}
               />
               <DeleteDialog
                  open={deleteModal}
                  handleCancel={() => setDeleteModal(!deleteModal)}
                  deleteFunc={deleteTariff}
                  title={selectedTariff && `Delete ${selectedTariff.fuelTariffName}`}
                  text={
                     selectedTariff &&
                     `Are you sure you want to delete ${selectedTariff.fuelTariffName} from the fuel tariffs?`
                  }
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      tariffs: state.fuel.tariff || [],
      customers: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getTariffs: () => dispatch(fuelActions.getTariffs()),
      getCustomers: () => dispatch(customerActions.getCustomers()),
   };
}

FuelView.propTypes = {
   tariffs: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   customers: PropTypes.array.isRequired,
   getTariffs: PropTypes.func.isRequired,
   getCustomers: PropTypes.func.isRequired,
};

FuelView.defaultProps = {
   tariffs: [],
   user: {},
   customers: [],
   getTariffs: () => {
      return;
   },
   getCustomers: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelView);
