import { DeleteForever, Edit } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import * as rateApi from "../../api/rateApi";
import { _handleObjectProperty } from "../../common/convert";
import { getAccessorialTariff } from "../../common/getAccessorialTariff";
import { getFuelTariff } from "../../common/getFuelTariff";
import RateManagementDetails from "./RateManagementDetails";

const useStyles = makeStyles(() => ({
   divider: {
      backgroundColor: "#D1D1D1",
      height: "25px",
      width: "2px",
      marginTop: ".5rem",
      marginRight: ".25rem",
   },
   resultsTitle: {
      color: "#002D72",
      fontWeight: 600,
      marginTop: ".5rem",
   },
}));

const RateManagementResults = (props) => {
   const classes = useStyles();
   var handleError = useErrorHandler();

   let [selectedRate, setSelectedRate] = useState(null);

   ////////////////details functions///////////////////////////////////////
   const openDetailsClick = (rate) => {
      try {
         setSelectedRate(rate);
         props.setView("details");
      } catch (err) {
         handleError(err);
      }
   };

   function closeDetails(isNew = false) {
      try {
         props.setView("");
         props.onSearchClicked(isNew);
      } catch (err) {
         handleError(err);
      }
   }

   /////////////overrides functions////////////////////////////////////////
   const openOverridesClick = async (rate) => {
      if (!rate.customer_id && !props.selectedCustomer) {
         props.openAlertMessage("Please Select a Customer.", "warning");
         return;
      }

      rate.fuelTariff = await getFuelTariff(
         rate,
         props.fuelTariffs,
         props.customers,
         props.selectedCustomer,
         props.terminals,
      );
      rate.accessorialTariff = await getAccessorialTariff(
         rate,
         props.accessorialTariffs,
         props.customers,
         props.selectedCustomer,
         props.terminals,
      );

      if (!rate.fuelTariff.tariffInfo) {
         props.openAlertMessage("No Fuel Tariff Found.", "error");
         return;
      }

      if (!rate.accessorialTariff.tariffInfo) {
         props.openAlertMessage("No Accessorial Tariff Found.", "error");
         return;
      }

      setSelectedRate(rate);
      props.setView("overrides");
   };

   function closeOverrides(isNew = false) {
      try {
         props.setView("");
         props.onSearchClicked(isNew);
      } catch (err) {
         handleError(err);
      }
   }

   ///////////rate functions for results/////////////////////
   function updateRate(rate) {
      if (rate.overrides) {
         delete rate.overrides;
      }

      rate = { ...rate, insertNew: false };

      rateApi
         .saveRate(rate)
         .then((resp) => {
            //success
            props.openAlertMessage("Rate Successfully Saved.", "success");
            closeDetails();
         })
         .catch();
   }

   function deleteRate(rate) {
      rateApi
         .deleteRate(rate._id, rate.terminal_id, rate.customer_id)
         .then((resp) => {
            //success
            props.openAlertMessage("Rate Successfully De-Activated.", "success");
            var newResults = _.cloneDeep(props.searchResults);
            _.remove(newResults, (x) => x._id === rate._id);
            props.setSearchResults(newResults);
         })
         .catch();
   }

   //////////////special views////////////////////////////////
   const calcCPGrate = (rateInfo) => {
      try {
         if (!rateInfo) {
            return 0;
         }

         return (
            +(rateInfo.base_rate ? rateInfo.base_rate : 0) +
            +(rateInfo.tolls ? rateInfo.tolls : 0) +
            +(rateInfo.bobtail ? rateInfo.bobtail : 0)
         );
      } catch (err) {
         handleError(err);
      }
   };

   const rateItemView = (rate) => {
      var tariff = "Default";

      if (props.customers.find((cust) => cust._id === rate.customer_id)) {
         tariff = props.customers.find((cust) => cust._id === rate.customer_id).name;
      }

      function getRateFuelAmount(rate, rateInfo) {
         try {
            if (!rateInfo) {
               return 0;
            }

            rate.selectedRateInfo = rateInfo;

            if (!rate.fuelTariff) {
               return 0;
            }

            var cpm = 0;
            var ppr = 0;

            if (rate.fuelTariff) {
               if (rate.fuelTariff.overUnder) {
                  cpm = rate.fuelTariff.overUnder.cpm;
                  ppr = rate.fuelTariff.overUnder.ppr;
               } else {
                  //get current tariff info if no over under exists
                  cpm = rate.fuelTariff.tariffInfo.cpm;
                  ppr = rate.fuelTariff.tariffInfo.ppr;
               }

               var cpgRate = calcCPGrate(rate.selectedRateInfo);

               if (cpm > 0.0) {
                  return parseFloat(cpm) * rate.selectedRateInfo.miles;
               } else {
                  //PPR
                  return (parseFloat(ppr) / 100) * parseFloat(cpgRate);
               }
            } else {
               //no tariff found in either default or customer
               return 0;
            }
         } catch (err) {
            handleError(err);
         }
      }

      return (
         <React.Fragment key={`RF${rate._id}`}>
            <Paper elevation={3} style={{ marginBottom: ".25rem" }}>
               <Grid
                  container
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ minHeight: "7rem" }}
               >
                  <Grid item xs={2} style={{ marginLeft: "4rem" }}>
                     <Typography align="left" className={classes.resultsTitle}>
                        Origin
                     </Typography>
                     <Typography align="left">
                        {_handleObjectProperty(
                           props.terminals.find((terminal) => {
                              return terminal._id === rate.terminal_id;
                           }),
                           "name",
                        )}
                     </Typography>
                  </Grid>
                  <Grid item xs={1}>
                     <ArrowForwardIcon
                        style={{
                           color: "#002D72",
                           fontWeight: 600,
                           marginTop: ".5rem",
                           marginLeft: "1rem",
                        }}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <Typography align="left" className={classes.resultsTitle}>
                        Destination
                     </Typography>
                     <Typography align="left">
                        {rate.destination.city + ", " + rate.destination.state + "  " + rate.destination.zip}
                     </Typography>
                  </Grid>
                  <Divider orientation="vertical" className={classes.divider} />
                  <Grid item xs={2}>
                     <Typography align="left" className={classes.resultsTitle}>
                        Tariff
                     </Typography>
                     {tariff === "Default" ? (
                        <Chip label={tariff} id="ci-tariff" color="primary" size="small" />
                     ) : (
                        <Typography align="left">{tariff}</Typography>
                     )}
                  </Grid>
                  <Divider orientation="vertical" className={classes.divider} />
                  <Grid item xs={1}>
                     <Typography align="left" className={classes.resultsTitle}>
                        CPG Rate
                     </Typography>
                     <Typography align="left">{"$" + calcCPGrate(rate.rateInfo)}</Typography>
                  </Grid>
                  <Divider orientation="vertical" className={classes.divider} />
                  <Grid item xs={1}>
                     <Typography align="left" className={classes.resultsTitle}>
                        FSC
                     </Typography>
                     <Typography align="left">
                        {"$" + Number.parseFloat(getRateFuelAmount(rate, rate.rateInfo)).toFixed(2)}
                     </Typography>
                  </Grid>
                  <Divider orientation="vertical" className={classes.divider} />
                  <Grid item xs={1}>
                     <Typography align="left" className={classes.resultsTitle}>
                        Miles
                     </Typography>
                     <Typography align="left">{rate.rateInfo ? rate.rateInfo.miles : 0}</Typography>
                  </Grid>
                  <Divider orientation="vertical" className={classes.divider} />
                  <Grid item xs={1}>
                     <Tooltip title="Edit Rate" arrow>
                        <IconButton
                           color="primaryLight"
                           disabled={props.user.security.editRate ? false : true}
                           variant="contained"
                           onClick={() => openDetailsClick(rate)}
                           style={{ marginTop: ".5rem" }}
                           size={"small"}
                        >
                           <Edit />
                        </IconButton>
                     </Tooltip>
                     <Tooltip title="Deactivate Rate" arrow>
                        <IconButton
                           color="secondary"
                           disabled={props.user.security.admin ? false : true}
                           variant="contained"
                           onClick={() => deleteRate(rate)}
                           style={{ marginTop: ".5rem" }}
                           size={"small"}
                        >
                           <DeleteForever />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
            </Paper>
         </React.Fragment>
      );
   };

   const RateView = (view) => {
      switch (view.view) {
         case "details":
            return (
               <RateManagementDetails
                  close={closeDetails}
                  update={updateRate}
                  selectedCustomer={props.selectedCustomer}
                  rate={selectedRate}
                  openAlertMessage={props.openAlertMessage}
                  readOnly={props.readOnly}
               />
            );
         default:
            return false;
      }
   };

   return (
      <>
         {!props.view ? (
            <Grid container direction={"row"}>
               <Grid item style={{ width: "-webkit-fill-available" }}>
                  <div style={{ width: "100%" }}>
                     <List>
                        {props.rates.map((rate) => {
                           return rateItemView(rate);
                        })}
                        {props.rates.length < props.rateCount && (
                           <Button
                              variant="contained"
                              style={{ width: "-webkit-fill-available" }}
                              color="primary"
                              onClick={props.onSearchClicked}
                           >
                              Load More
                           </Button>
                        )}
                     </List>
                  </div>
               </Grid>
            </Grid>
         ) : (
            <RateView view={props.view} />
         )}
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      customers: state.customer !== null ? state.customer.customer : [],
      fuelTariffs: state.fuel.tariff || [],
      accessorialTariffs: state.accessorial.tariff || [],
      user: state.user.currentUser,
   };
}

RateManagementResults.propTypes = {
   view: PropTypes.string.isRequired,
   rateCount: PropTypes.number.isRequired,
   accessorialTariffs: PropTypes.array.isRequired,
   customers: PropTypes.array.isRequired,
   fuelTariffs: PropTypes.array.isRequired,
   searchResults: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   rates: PropTypes.array.isRequired,
   selectedCustomer: PropTypes.object,
   user: PropTypes.object.isRequired,
   addToCart: PropTypes.func.isRequired,
   onSearchClicked: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
   setSearchResults: PropTypes.func.isRequired,
};

RateManagementResults.defaultProps = {
   view: "",
   rateCount: 0,
   accessorialTariffs: [],
   customers: [],
   fuelTariffs: [],
   searchResults: [],
   terminals: [],
   rates: [],
   selectedCustomer: {},
   user: {},
   addToCart: () => {
      return;
   },
   onSearchClicked: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
   setSearchResults: () => {
      return;
   },
};

export default connect(mapStateToProps)(RateManagementResults);
